import React, { cloneElement } from 'react';
import useForm from 'react-hook-form';
import Coordinates from '../components/Coordinates';
import UploadImage from '../components/UploadImage';
import Rankings from '../components/Rankings';
import UploadTinyMce from '../components/UploadTinyMce';
import Races from '../components/Races';
import Items from '../components/Items';
import PromoCodes from '../components/PromoCodes';
// import TextEditor from './TextEditor';
import cookie from 'react-cookies';
import { Editor } from '@tinymce/tinymce-react';

export default function Form(props) {
    const { register, handleSubmit, errors } = useForm();
    if(props.dataevent === undefined){
        var races = [];
        var items = [];
        var promos = [];
        var rankings = [];
    }else{
        var races = props.dataevent.races || [];
        var items = props.dataevent.items || [];
        var promos = props.dataevent.promoCodes || [];
        var rankings = props.dataevent.rankings || {items: [], titles: []};
    }
    
    let event = props.dataevent || {description: 'Votre <b>description</b> ici...', promoCodes: []};
    let cookies = cookie.loadAll();
    let tokenId = '';
    event.counter = Boolean(event.counter);
    event.external_register = Boolean(event.external_register);
    event.show_preinscription = Boolean(event.show_preinscription);
    event.clone = false;

    console.log(event)

    // for(let i=0;i<event.races.length;i++){
    //     event.races[i].results = '';
    // }

    // console.log(event)
    
    // ADD DESC
    if(event.description === '<p></p>\n'){
        event.description = 'Votre <b>description</b> ici...';
    }

    // FORMAT LIVE
    if(event.live == 'none'){
        event.live = ''
    }
   
    if(!cookies.tokenId){
        let d = new Date();
        var url = window.location.href;
        var url = window.location.hash;
        var selected = url.split('id_token' + '=');
        if(selected[1] !== undefined){
            var value = selected[1].split('&');
            let tid = value[0];
            
            d.setTime(d.getTime() + (55*60*1000));
            cookie.save("tokenId", tid, {path: "/", expires: d});

            tokenId = tid;
        }
    }else{
        tokenId = cookies.tokenId;
    }

    const onSubmit = function(d){

        if(d.live == ''){
            d.live = 'none'
        }

        var data = {
            date: Math.floor(new Date(d.date + 'T' + d.time).getTime()/1000),
            name: d.name,
            type: d.type,
            description: event.description,
            banner: d.banner,
            banner_mobile: d.banner_mobile,
            note: d.note,
            place: d.place,
            address: d.adresse + '<br />' + d.cp + ' ' + d.ville + '<br />' + d.pays,
            longitude: d.longitude,
            latitude: d.latitude,
            live: d.live,
            registrationStartDate: Math.floor(new Date(d.registrationStartDate + 'T' + d.registrationStartTime).getTime()/1000),
            registrationEndDate: Math.floor(new Date(d.registrationEndDate + 'T' + d.registrationEndTime).getTime()/1000),
            // organizer: {},
            counter: new Boolean(d.counter),
            external_register: new Boolean(d.external_register),
            show_preinscription: new Boolean(d.show_preinscription),
            show_qr: new Boolean(d.show_qr),
            trad: new Boolean(d.trad),
            promoCodes: promos,
            items: items,
            races: races,
            rankings: rankings,
            addRules: d.addRules,
            publicationDate: Math.floor(new Date(d.publicationDate + 'T' + d.publicationTime).getTime()/1000),
            frais: d.frais
        };

        if(d.startDate && d.startTime){
            data.startDate = Math.floor(new Date(d.startDate + 'T' + d.startTime).getTime()/1000);
        }else{
            data.startDate = -3600;
        }

        if(!props.dataevent || event.clone){
            var xhr = new XMLHttpRequest()
            xhr.open('POST', 'https://api.goaltiming.be/events')
            xhr.setRequestHeader('Authorization', tokenId)
            xhr.send(JSON.stringify(data))
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    window.location.replace('/created');
                }
            }
        }else{
            var xhr = new XMLHttpRequest()
            xhr.open('PATCH', 'https://api.goaltiming.be/events/' + event.EventId)
            xhr.setRequestHeader('Authorization', tokenId)
            xhr.send(JSON.stringify(data))
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    window.location.replace('/edit');
                }
            }
        }
    }
    

    const callbackRace = function(dataFromChild){
        races = dataFromChild;
    }

    const callbackItem = function(dataFromChild){
        items = dataFromChild;
    }

    const callbackPromos = function(dataFromChild){
        promos = dataFromChild;
    }

    const callbackRankings = function(dataFromChild){
        rankings = dataFromChild;
    }

    const convertToDate = function(timestamp){
        var date = new Date(timestamp*1000);
        var day = ("0" + date.getDate()).slice(-2)
        day = day < 10 ? '0'+day : day;
        day = day.length > 2 ? day.substr(1) : day;
        var month = date.getMonth()+1;
        month = month < 10 ? '0'+month : month;
        month = month.length > 2 ? month.substr(1) : month;
        var year = date.getFullYear();

        return year + "-" + month + "-" + day;
    }

    const handleEditorChange = function(e){
        event.description = e;
    }

    const clone = function(){
        event.clone = true;
    }

    const convertToTime = function(timestamp){
        var date = new Date(timestamp*1000);
        var hour = date.getHours();
        hour = hour < 10 ? '0'+hour : hour;
        var minutes = date.getMinutes();
        minutes = minutes < 10 ? '0'+minutes : minutes;

        return hour + ":" + minutes;
    }

    return (
        <form className="main-body" onSubmit={handleSubmit(onSubmit)}>
        <div className="page-wrapper">
            {/* [ Main Content ] start */}
            <div className="row">
                <div className="col-sm-12">

                    {/* [Infos] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Informations sur l'évènement</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nom de l'évènement</label>
                                        <input type="text" className="form-control" name="name" placeholder="Nom" defaultValue={event.name} ref={register({required: true})} />
                                        <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                                    </div>
                                    <div className="form-group">
                                        <label>Date de l'évènement</label>
                                        <div className="row">
                                            {event.startDate != -3600 ? (
                                                <div className="col-md-3">
                                                    <input type="date" className="form-control" name="startDate" defaultValue={convertToDate(event.startDate)} ref={register} />
                                                    <small className="form-text form-error">{errors.date && <span>Ce champ est requis</span>}</small>
                                                </div>
                                            ) : (
                                                <div className="col-md-3">
                                                    <input type="date" className="form-control" name="startDate" ref={register} />
                                                    <small className="form-text form-error">{errors.date && <span>Ce champ est requis</span>}</small>
                                                </div>
                                            )}
                                            {event.startDate != -3600 ? (
                                                <div className="col-md-3">
                                                    <input type="time" className="form-control" name="startTime" defaultValue={convertToTime(event.startDate)} ref={register} />
                                                    <small className="form-text form-error">{errors.time && <span>Ce champ est requis</span>}</small>
                                                </div>
                                            ) : (
                                                <div className="col-md-3">
                                                    <input type="time" className="form-control" name="startTime" ref={register} />
                                                    <small className="form-text form-error">{errors.time && <span>Ce champ est requis</span>}</small>
                                                </div>
                                            )}
                                            <div className="col-md-3">
                                                <input type="date" className="form-control" name="date" defaultValue={convertToDate(event.date)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.date && <span>Ce champ est requis</span>}</small>
                                            </div>
                                            <div className="col-md-3">
                                                <input type="time" className="form-control" name="time" defaultValue={convertToTime(event.date)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.time && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Type d'évènement</label>
                                        <input type="text" className="form-control" name="type" defaultValue={event.type} placeholder="Type" ref={register} />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Bannière</label>
                                                <UploadImage dataref={register} dataerrors={errors} dataname="banner" dataimg={event.banner} datatoken={tokenId} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Mobile</label>
                                                <UploadImage dataref={register} dataerrors={errors} dataname="banner_mobile" dataimg={event.banner_mobile} datatoken={tokenId} />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Ouverture des inscriptions</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" name="registrationStartDate" defaultValue={convertToDate(event.registrationStartDate)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.registrationStartDate && <span>Ce champ est requis</span>}</small>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="time" className="form-control" name="registrationStartTime" defaultValue={convertToTime(event.registrationStartDate)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.registrationStartTime && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Fermeture des inscriptions</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                            <input type="date" className="form-control" name="registrationEndDate" defaultValue={convertToDate(event.registrationEndDate)} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.registrationEndDate && <span>Ce champ est requis</span>}</small>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="time" className="form-control" name="registrationEndTime" defaultValue={convertToTime(event.registrationEndDate)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.registrationEndTime && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-12">
                                    <label>Frais d'inscription</label>
                                    <input type="number" className="form-control" step="0.01" min="0" name="frais" defaultValue={event.frais} ref={register({required: true})} />
                                    <small className="form-text form-error">{errors.frais && <span>Ce champ est requis</span>}</small>
                                </div>

                                <div className="col-md-12">
                                        <label>Upload d'image pour description</label>
                                        <UploadTinyMce datatoken={tokenId} />
                                    </div>
                                <div className="col-md-12">
                                    <label>Description</label>
                                    {/* <TextEditor dataref={register} dataerrors={errors} desc={event.description} /> */}
                                    <Editor
                                        dataref={register}
                                        dataerrors={errors}
                                        apiKey="id6h4a0vckr2ki3smmblil3g79szn50tlogdqr0893u6n0n1"
                                        initialValue={event.description}
                                        init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic underline strikethrough fontsizeselect forecolor backcolor image | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* [Infos] END */}

                    {/* [Address] START */}
                        <Coordinates dataregister={register} dataaddress={event.address} datalat={event.latitude} datalon={event.longitude} dataplace={event.place} />
                    {/* [Address] END */}

                    {/* [Courses] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Details des courses</h5>
                        </div>

                        <Races dataref={register} dataerrors={errors} callbackRace={callbackRace} dataraces={event.races} datatoken={tokenId} />
                    </div>
                    {/* [Courses] END */}

                    {/* [Courses] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Objets complémentaires</h5>
                        </div>
                        
                        <Items dataref={register} dataerrors={errors} callbackItem={callbackItem} dataitems={event.items} datatoken={tokenId} />
                    </div>
                    {/* [Courses] END */}

                    {/* [Promo code] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Code promo</h5>
                        </div>

                        <PromoCodes dataref={register} dataerrors={errors} callbackPromos={callbackPromos} datapromos={event.promoCodes} />
                    </div>

                    {/* [Rankings] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Classements d'Evènement</h5>
                        </div>

                        <Rankings dataref={register} dataerrors={errors} datatoken={tokenId} callbackRankings={callbackRankings} datarankings={event.rankings} />
                    </div>
                    {/* [Rankings] END */}

                    {/* [Publication] START */}
                    <div className="card">
                        <div className="card-header">
                            <h5>Information de publication</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Live</label>
                                        <input type="text" className="form-control" name="live" placeholder="ID Live" defaultValue={event.live} ref={register} />
                                    </div>
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <div className="form-group">
                                            <textarea className="form-control" name="note" defaultValue={event.note} ref={register}></textarea>
                                        </div>
                                        <label>Document</label>
                                        <div className="form-group">
                                            <UploadImage dataref={register} dataerrors={errors} dataname="addRules" dataimg={event.addRules} datatoken={tokenId} />
                                        </div>
                                        <label>Date de publication</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" name="publicationDate" defaultValue={convertToDate(event.publicationDate)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.publicationDate && <span>Ce champ est requis</span>}</small>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="time" className="form-control" name="publicationTime" defaultValue={convertToTime(event.publicationDate)} ref={register({required: true})} />
                                                <small className="form-text form-error">{errors.publicationTime && <span>Ce champ est requis</span>}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Compteur</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {event.counter}
                                                    <input name="counter" type="checkbox" value="true" defaultChecked={event.counter} ref={register} /> Afficher un compteur
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Inscriptions externes</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {event.external_register}
                                                    <input name="external_register" type="checkbox" value="true" defaultChecked={event.external_register} ref={register} /> Oui
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Masquer les participants</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {event.show_preinscription}
                                                    <input name="show_preinscription" type="checkbox" value="true" defaultChecked={event.show_preinscription} ref={register} /> Oui
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Afficher QR Code</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {event.show_qr}
                                                    <input name="show_qr" type="checkbox" value="true" defaultChecked={event.show_qr} ref={register} /> Oui
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Afficher Néerlandais</label>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {event.trad}
                                                    <input name="trad" type="checkbox" value="true" defaultChecked={event.trad} ref={register} /> Oui
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button className="btn btn-primary" type="submit">Enregistrer</button>

                                        {/* <button className="btn btn-primary" type="submit" style={{background:'#D00000', borderColor:'#D00000'}} onClick={clone}>Cloner</button> */}
                                    </div>
                                </div>                                                       
                            </div>
                        </div>
                    </div>
                    {/* [Publication] END */}

                </div>
            </div>
            {/* [ Main Content ] end */}
        </div>
    </form>
    );
}